import { formatForId } from "../Lang/IntlHelper";
import { formatWMOCode } from "./WMOUtils";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import _ from "loadsh";
import { NIRA_ALERT_COLOR_ORANGE, NIRA_ALERT_COLOR_RED } from "../../Components/Map/Nira/Gradient";

export const ALERT_1 = 1;
export const ALERT_2 = 2;
export const ALERT_3 = 3;
export const ALERT_FORECAST_1 = 11;
export const ALERT_FORECAST_2 = 12;
export const ALERT_FORECAST_3 = 13;

export const ALERT_DEVICE_OFFLINE = 100;
export const ALERT_STATE = 20;
export const ALERT_FAILURE = 30;

export const NIRA_ALERT_NONE = 0;
export const NIRA_ALERT_YELLOW = 1;
export const NIRA_ALERT_RED = 2;

export const WarningSeverity = [ALERT_1, ALERT_2, ALERT_3, ALERT_FORECAST_1, ALERT_FORECAST_2, ALERT_FORECAST_3, ALERT_DEVICE_OFFLINE, ALERT_STATE, ALERT_FAILURE];
export const CONFIRMABLE_ALERTS = [ALERT_1, ALERT_2, ALERT_3, ALERT_FAILURE, ALERT_STATE];
export const NiraAlertSeverity = [NIRA_ALERT_YELLOW, NIRA_ALERT_RED];

const ZNP_FILTER_VALUES = ["dew_point", "frost_it_senc_pws", "alarm_it_senc_pws", "alarm_it_arctis_pws"];
const BOSCHUNG_FILTER_VALUES = ["boschung.A1", "boschung.A2", "boschung.A3", "boschung.A2GT", "boschung.A3GT", "boschung.A2BOSO", "boschung.A3BOSO", "boschung.A2BOPAS", "boschung.A3BOPAS"];
const ZPI1_FILTER_VALUES = ["zpi_pws_air_temp", "zpi_pws_road_temp", "zpi_snow"];
const ZPI2_FILTER_VALUES = ["zpi_dew_point"];
const ZPI3_FILTER_VALUES = ["zpi_road_status", "zpi_frost_1", "zpi_frost_2", "zpi_black_ice"];

export function formatWarning(intl, alert, deviceState = {}, shortcut = false) {
    return formatWarningWithWMO(intl, alert, deviceState?.data?.meteo_v1?.precip_type?.value?.value, shortcut);
}

export function formatWarningWithWMO(intl, alert, wmo, shortcut = false) {
    if (alert.level === 100) {
        return formatForId(intl, `${shortcut ? "shortcutAlert" : "alert"}.type.device_offline.name`);
    } else return formatAlertWithWMO(intl, alert, wmo, shortcut);
}

function formatAlertWithWMO(intl, alert, wmo, shortcut = false) {
    switch (alert.type) {
        case "strong_wind":
        case "strong_side_wind":
        case "dew_point":
        case "frost_it_senc_pws":
        case "alarm_it_senc_pws":
        case "alarm_it_arctis_pws":
        case "visibility":
        case "zpi_visibility":
        case "zpi_strong_wind":
        case "zpi_strong_side_wind":
        case "zpi_dew_point":
        case "zpi_frost_1":
        case "zpi_road_status":
        case "zpi_snow":
        case "zpi_friction":
        case "zpi_black_ice":
        case "zpi_frost_2":
            return formatForId(intl, `${shortcut ? "shortcutAlert" : "alert"}.type.${alert.type}.${alert.level}`);
        case "pws_air_temp":
        case "pws_road_temp":
        case "zpi_pws_air_temp":
        case "zpi_pws_road_temp":
            return formatWMOCode(intl, wmo);
        default:
            return formatForId(intl, `${shortcut ? "shortcutAlert" : "alert"}.type.${alert.type}.name`);
    }
}

export function filterAlert(alert, includeOffline = true) {
    return alert.level === 1 || alert.level === 2 || alert.level === 3 || (includeOffline && alert.level === 100);
}

export function getDeviceStateSeverity(deviceState, includeOffline = true) {
    const alert = _.maxBy(
        deviceState?.active_warnings?.filter((alert) => filterAlert(alert, includeOffline)),
        (alert) => (alert.level === ALERT_DEVICE_OFFLINE ? -1 : alert.level)
    );
    return alert ? alert.level : 0;
}

export function getAlertColor(level, theme) {
    const levelColor = theme.palette.warnings[`level_${level}`];
    if (levelColor) {
        return levelColor;
    } else if (level === ALERT_FORECAST_1 || level === ALERT_FORECAST_2 || level === ALERT_FORECAST_3) {
        return theme.palette.warnings[`level_${level - 10}`];
    } else return {};
}

export function getNiraAlertColor(level, theme) {
    if (level === NIRA_ALERT_YELLOW) {
        return theme.palette.warnings.nira_level_1;
    } else if (level === NIRA_ALERT_RED) {
        return theme.palette.warnings.nira_level_2;
    } else return {};
}

function filterWarningsGroups(filter, warnings) {
    let last_level = 0;

    return warnings.filter((wrn) => {
        if (filter.includes(wrn.type)) {
            const filter = wrn.level > last_level;
            last_level = wrn.level;
            return filter;
        } else return true;
    });
}

export function useFormattedWarnings(warnings, deviceState, filter = [ALERT_1, ALERT_2, ALERT_3]) {
    const intl = useIntl();

    return useMemo(() => {
        warnings = warnings.filter((wrn) => filter.includes(wrn.level));
        warnings = filterWarningsGroups(ZNP_FILTER_VALUES, warnings);
        warnings = filterWarningsGroups(BOSCHUNG_FILTER_VALUES, warnings);
        warnings = filterWarningsGroups(ZPI1_FILTER_VALUES, warnings);
        warnings = filterWarningsGroups(ZPI2_FILTER_VALUES, warnings);
        warnings = filterWarningsGroups(ZPI3_FILTER_VALUES, warnings);

        return warnings
            .map((wrn) => formatWarning(intl, wrn, deviceState))
            .filter((wrn, index, arr) => arr.indexOf(wrn) === index)
            .join(", ");
    }, [warnings]);
}

export function formatForecastWarnings(warnings, intl, level, shortcut = false) {
    if (_.isEmpty(warnings)) {
        return formatForId(intl, `alert.forecastAlert.none`, { level: level - 10 });
    }

    const getPriority = (key) => {
        if (key.includes("rc")) return 1;
        if (key.includes("wx")) return 2;
        return 3;
    };

    const formattedWarnings = warnings
        .map((warning) => ({
            key: warning.type,
            formatted: formatAlertWithWMO(intl, warning, null, shortcut),
            priority: getPriority(warning.type),
        }))
        .sort((a, b) => (a.priority > b.priority ? 1 : -1));

    return formatForId(intl, `${shortcut ? "shortcutAlert" : "alert"}.forecastAlert.${warnings.length}`, {
        wrn_1: formattedWarnings[0].formatted,
        wrn_2: formattedWarnings[1]?.formatted,
        wrn_3: formattedWarnings[2]?.formatted,
    });
}
