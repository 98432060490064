import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import React, {useMemo, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import UserActions from "../../Components/Navigation/UserActions";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import Icon from "@mdi/react";
import {mdiCalendar, mdiCamera, mdiDebugStepInto, mdiMap, mdiTable, mdiTableColumn, mdiTrafficLight} from "@mdi/js";
import {IconButton, InputAdornment, TextField, Tooltip, Typography, useTheme} from "@material-ui/core";
import {formatForId} from "../../Utils/Lang/IntlHelper";
import {useIntl} from "react-intl";
import moment from "moment";
import {ActiveAlerts, ActiveGeoAlerts} from "../../Components/Device/ActiveAlerts";
import {ActiveTextForecast} from "../../Components/TextForecast/ActiveTextForecast";
import {useHistory, useLocation} from "react-router-dom";
import _ from "loadsh";
import {useStore} from "react-redux";
import {openDialog} from "../../Utils/Data/actions/gui";
import {
    RequireAnyPermission,
    RequireAnyPermissionsForVisibleDevices,
    useHasAnyPermission, useHasPermission
} from "../../Utils/Permissions/RequireAnyPermission";
import {PermsCamera} from "../../Utils/Permissions/Permissions";
import {setActiveDeviceDataViewSearchKey} from "../../Utils/Data/actions/deviceDataView";
import {DebounceInput} from "react-debounce-input";
import DeviceSearch from "../../Components/Navigation/DeviceSearch";
import ViewsButton from "../../Components/Navigation/VIewsButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightToLine, faVolume, faVolumeSlash} from "@fortawesome/pro-solid-svg-icons";
import {
    useActiveDeviceDataViewSearchKey,
    useAppFieldsOrder,
    useViewConfig,
    useViewMapConfig
} from "../../Utils/Data/hooks/deviceDataView";
import DesktopOnlyView from "../../Utils/Hooks/DesktopOnlyView";
import {useIntervalWhen} from "rooks";
import {useAudioAlertEnabled, useHistoryEndDate} from "../../Utils/Data/hooks/gui";
import clsx from "clsx";
import ViewDateTime from "../../Components/DateTime/ViewDateTime";
import {useHistoryDate} from "../../Utils/Data/useHistoryDate";
import {useAppAccess} from "../../Utils/Data/hooks/server";

const ITEM_HEIGHT = 38;

const useStyles = makeStyles((theme) => ({
    appBar: {
        flex: 1,
        padding: theme.spacing(2),
    },
    viewBar: {},
    toolBar: {
        paddingTop: theme.spacing(2),
    },
    timeSelect: {
        paddingLeft: theme.spacing(1),
        minWidth: 235,
    },
    active: {
        minWidth: 270,
    },
    inputChanged: {
        color: theme.palette.historyActiveColor + " !important",
        width: "100%",
    },
    searchInput: {
        minWidth: 210,
        paddingLeft: theme.spacing(1),
    },
}));

function TimeSelect({date, onChange, active, onHistoryReset, onOpen}) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();

    React.useEffect(() => {
        const listener = (event) => {
            if (!event.target.matches(".MuiPickersPopper-root") && !event.target.matches(".MuiPickersPopper-root *") && !event.target.matches(".MyPicker")) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", listener);
        return () => {
            document.removeEventListener("mousedown", listener);
        };
    }, [open]);

    return (
        <div className={clsx(classes.timeSelect, active && classes.active)}>
            <ViewDateTime
                value={date}
                disableFuture
                onOpen={onOpen}
                renderInput={(props) => {
                    if (active) {
                        props.className = classes.inputChanged;
                        props.value = `${props.value} ${formatForId(intl, "pages.appBar.history.historyActiveTooltip")}`;
                    }
                    return (
                        <Tooltip title={formatForId(intl, "pages.appBar.tooltip.calendar")}>
                            <TextField
                                {...props}
                                helperText={""}
                                variant={"outlined"}
                                size={"small"}
                                onMouseDown={() => {
                                    setOpen(true);
                                }}
                                InputProps={{
                                    readOnly: true,
                                    className: active ? classes.inputChanged : "",
                                    endAdornment: (
                                        <InputAdornment position={"end"}>
                                            <Icon path={mdiCalendar} size={1} style={{cursor: "pointer"}}
                                                  color={active ? theme.palette.historyActiveColor : theme.palette.text.primary}/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Tooltip>
                    );
                }}
                onValueChanged={(date) => onChange(date)}
            />
        </div>
    );
}

export function HistoryCountdown({endDate, onExpiration}) {
    const [remaining, setRemaining] = useState(moment.utc(endDate.diff(moment.now())));

    useIntervalWhen(
        () => {
            const tmpRemaining = moment.utc(endDate.diff(moment.now()));

            if (tmpRemaining < 0) {
                if (onExpiration) {
                    onExpiration();
                }
            } else {
                setRemaining(tmpRemaining);
            }
        },
        1000,
        true,
        true
    );

    return <Typography variant={"h6"}>{remaining.format("mm:ss")}</Typography>;
}

export function ResetHistory({historyActive, onReset}) {
    const intl = useIntl();
    if (historyActive) {
        return (
            <Tooltip title={formatForId(intl, "actions.reset")}>
                <IconButton color="inherit" onClick={onReset}>
                    <FontAwesomeIcon icon={faArrowRightToLine}/>
                </IconButton>
            </Tooltip>
        );
    } else {
        return <></>;
    }
}

export function ViewToolbar() {
    const classes = useStyles();
    const intl = useIntl();
    const location = useLocation();
    const history = useHistory();
    const store = useStore();
    const searchKey = useActiveDeviceDataViewSearchKey();

    const selectedView = useMemo(() => {
        const pathParts = location.pathname.split("/");
        return _.findLast(pathParts, (part) => part.length > 0);
    }, [location.pathname]);

    const [level, soundAlertEnabled, setSoundAlertEnabled, originalLevel] = useAudioAlertEnabled();

    const appFields = useAppFieldsOrder();
    const viewMapConfig = useViewMapConfig();
    const viewConfig = useViewConfig();

    const {
        historyActive,
        date,
        onHistoryExpiration,
        handleDateChange,
        resetHistory,
        setRefreshCurrentTime
    } = useHistoryDate();
    const endDate = useHistoryEndDate();

    const handleViewChange = (change, value) => {
        if (value) {
            history.push("../" + value + "/");
        }
    };

    const ButtonsWithCamera = (
        <ToggleButtonGroup value={selectedView} onChange={handleViewChange} exclusive style={{height: ITEM_HEIGHT}}>
            <ToggleButton value={"map"}>
                <Tooltip title={formatForId(intl, "pages.appBar.tooltip.map")}>
                    <Icon path={mdiMap} size={1}/>
                </Tooltip>
            </ToggleButton>
            <ToggleButton value={"table"}>
                <Tooltip title={formatForId(intl, "pages.appBar.tooltip.table")}>
                    <Icon path={mdiTableColumn} size={1}/>
                </Tooltip>
            </ToggleButton>
            <ToggleButton value={"column"}>
                <Tooltip title={formatForId(intl, "pages.appBar.tooltip.column")}>
                    <Icon path={mdiTable} size={1}/>
                </Tooltip>
            </ToggleButton>
            {appFields.indexOf("camera") !== -1 && (
                <ToggleButton value={"camera"}>
                    <Tooltip title={formatForId(intl, "pages.appBar.tooltip.camera")}>
                        <Icon path={mdiCamera} size={1}/>
                    </Tooltip>
                </ToggleButton>
            )}
        </ToggleButtonGroup>
    );

    const ButtonsWithoutCamera = (
        <ToggleButtonGroup value={selectedView} onChange={handleViewChange} exclusive style={{height: ITEM_HEIGHT}}>
            <ToggleButton value={"map"}>
                <Tooltip title={formatForId(intl, "pages.appBar.tooltip.map")}>
                    <Icon path={mdiMap} size={1}/>
                </Tooltip>
            </ToggleButton>
            <ToggleButton value={"table"}>
                <Tooltip title={formatForId(intl, "pages.appBar.tooltip.table")}>
                    <Icon path={mdiTableColumn} size={1}/>
                </Tooltip>
            </ToggleButton>
            <ToggleButton value={"column"}>
                <Tooltip title={formatForId(intl, "pages.appBar.tooltip.column")}>
                    <Icon path={mdiTable} size={1}/>
                </Tooltip>
            </ToggleButton>
        </ToggleButtonGroup>
    );

    const ButtonsMapOnly = (
        <ToggleButtonGroup value={selectedView} onChange={handleViewChange} exclusive style={{height: ITEM_HEIGHT}}>
            <ToggleButton value={"map"}>
                <Tooltip title={formatForId(intl, "pages.appBar.tooltip.map")}>
                    <Icon path={mdiMap} size={1}/>
                </Tooltip>
            </ToggleButton>
        </ToggleButtonGroup>
    );

    const canRnrDebug = useHasPermission({permission: "rnr__debug_state"});
    const RnrButtons = (
        <ToggleButtonGroup value={selectedView} onChange={handleViewChange} exclusive style={{height: ITEM_HEIGHT}}>
            {canRnrDebug && <ToggleButton value={"rnrDebug"}>
                <Tooltip title={formatForId(intl, "pages.appBar.tooltip.rnrDebug")}>
                    <Icon path={mdiDebugStepInto} size={1}/>
                </Tooltip>
            </ToggleButton>}
            <ToggleButton value={"rnrBasic"}>
                <Tooltip title={formatForId(intl, "pages.appBar.tooltip.rnrBasic")}>
                    <Icon path={mdiTrafficLight} size={1}/>
                </Tooltip>
            </ToggleButton>
        </ToggleButtonGroup>
    );

    const onSearchChange = (evt) => {
        const value = evt.target.value;
        if (_.isEmpty(value)) {
            setActiveDeviceDataViewSearchKey(store, "");
        } else {
            setActiveDeviceDataViewSearchKey(store, value);
        }
    };

    return (
        <AppBar position="sticky" elevation={0}>
            <Toolbar className={classes.viewBar}>
                <ViewsButton/>
                {viewConfig.type === "rnr" ? (
                    <>{RnrButtons}</>
                ) : _.isEmpty(viewMapConfig) ? (
                    <RequireAnyPermissionsForVisibleDevices onErrorComponent={() => ButtonsWithoutCamera}
                                                            permissions={PermsCamera}>
                        {ButtonsWithCamera}
                    </RequireAnyPermissionsForVisibleDevices>
                ) : (
                    <>{ButtonsMapOnly}</>
                )}

                <DesktopOnlyView>
                    <RequireAnyPermission permission={"dev__data_history"} onErrorComponent={() => false}>
                        <TimeSelect onChange={handleDateChange} active={historyActive} date={date}
                                    onOpen={(open) => setRefreshCurrentTime(!open)}/>

                        <ResetHistory historyActive={historyActive} onReset={resetHistory}/>
                    </RequireAnyPermission>
                </DesktopOnlyView>

                {historyActive && endDate !== null && (
                    <Tooltip title={formatForId(intl, "actions.remainingHistoryTimeTooltip")}>
                        <div style={{paddingRight: 8, paddingLeft: 8}}>
                            <HistoryCountdown onExpiration={onHistoryExpiration} endDate={endDate}/>
                        </div>
                    </Tooltip>
                )}

                <RequireAnyPermission permission={"dev__alert_view"} onErrorComponent={() => false}>
                    <ActiveAlerts onClick={() => openDialog(store, {type: "alertHistory"})}/>
                </RequireAnyPermission>

                <RequireAnyPermission permission={"geo_alerts__view"} onErrorComponent={() => false}>
                    <ActiveGeoAlerts onClick={() => openDialog(store, {type: "niraAlertHistory"})}/>
                </RequireAnyPermission>

                <RequireAnyPermission permission={"forecast__text"} onErrorComponent={() => false}>
                    <ActiveTextForecast onClick={() => openDialog(store, {type: "textForecast"})}/>
                </RequireAnyPermission>

                <DesktopOnlyView>
                    {originalLevel > 0 && (
                        <Tooltip
                            title={formatForId(intl, soundAlertEnabled ? "actions.audio.off" : "actions.audio.on")}>
                            <IconButton onClick={() => setSoundAlertEnabled(!soundAlertEnabled)}>
                                <FontAwesomeIcon icon={soundAlertEnabled ? faVolume : faVolumeSlash}/>
                            </IconButton>
                        </Tooltip>
                    )}
                    <div className={classes.searchInput}>
                        <DebounceInput element={DeviceSearch} minLength={1} debounceTimeout={300}
                                       onChange={onSearchChange} value={searchKey}/>
                    </div>
                </DesktopOnlyView>
                <UserActions/>
            </Toolbar>
        </AppBar>
    );
}
