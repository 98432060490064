import { IconButton, Tooltip, Typography, useTheme } from "@material-ui/core";
import { useValueInfos } from "../../../Utils/Data/ValueMapper";
import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import { formatTime } from "../../../Utils/Data/Time";
import { useCrosshairData } from "../../../Utils/Data/hooks/meteogram";
import {
    ALERT_1,
    ALERT_2,
    ALERT_3,
    ALERT_DEVICE_OFFLINE,
    ALERT_FAILURE,
    ALERT_FORECAST_1,
    ALERT_FORECAST_2,
    ALERT_FORECAST_3,
    ALERT_STATE,
    formatForecastWarnings,
    formatWarning,
} from "../../../Utils/Data/AlertFormatter";
import _ from "loadsh";
import { PropertySelectionList } from "../../PropertySelectionList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlink } from "@fortawesome/pro-light-svg-icons";
import { useStore } from "react-redux";
import { resetStickSelection } from "../../../Utils/Data/actions/meteogram";
import { useDataFieldsOrder } from "../../../Utils/Data/hooks/deviceDataView";
import { useDeviceState } from "../../../Utils/Data/hooks/server";

export const DataPanelAlertPositionKeys = [ALERT_DEVICE_OFFLINE, ALERT_FAILURE, ALERT_STATE, ALERT_3, ALERT_2, ALERT_1];

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
    },
    fields: {
        flexGrow: 5,
        overflowY: "auto",
        overflowX: "hidden",
        flexBasis: 0,
    },
    legend: {
        flexGrow: 1,
        flexBasis: 0,
        paddingBottom: 32,
    },
    deviceState: {
        display: "flex",
    },
    deviceStateSection: {
        /* padding: theme.spacing(1),*/
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(0.5),
        paddingRight: theme.spacing(1),
    },
    shortcut: {
        color: (props) => props.color,
    },
    alerts: {
        minHeight: 130,
    },
    dataInfo: {},
}));

function DataFields({ columns, hiddenColumns, onColumnsChanged, selectedRow, selectedForecastRow, forecastActive, compact }) {
    const [valueInfos, findValueInfo] = useValueInfos(true);
    const fieldsOrder = useDataFieldsOrder();

    const showForecast = useMemo(() => {
        return forecastActive && _.isEmpty(selectedRow) && !_.isEmpty(selectedForecastRow);
    }, [forecastActive, selectedForecastRow, selectedRow]);

    const selectionListProps = useMemo(() => {
        let props;
        if (showForecast) {
            props = { fields: [], columns: [{ id: "forecastDataRow", width: 150 }] };
        } else {
            props = { fields: [], columns: [{ id: "dataRow", width: 150 }] };
        }

        for (const fieldOrder of fieldsOrder) {
            const field = findValueInfo(fieldOrder);

            if (hiddenColumns && hiddenColumns.has(field.valueKey)) {
                continue;
            }
            if (!field.hasOwnProperty("shownInGraph") || field.shownInGraph) {
                props.fields.push({
                    id: field.valueKey,
                    color: field.color,
                    valueFormatter: (value) => field.graphFormatter(value),
                    disabled: field.shownInGraphValueOnly,
                });
            }
        }

        return props;
    }, [valueInfos, forecastActive, hiddenColumns, showForecast]);

    const [key, setKey] = useState(0);
    React.useEffect(() => {
        setKey((key) => key + 1);
    }, [hiddenColumns]);

    const data = useMemo(
        () => ({
            dataRow: selectedRow || {},
            forecastDataRow: selectedForecastRow || {},
        }),
        [selectedRow, selectedForecastRow]
    );

    if (_.isEmpty(selectionListProps.fields)) {
        return <></>;
    } else
        return (
            <PropertySelectionList
                key={key}
                isSelected={(fieldId) => columns.has(fieldId)}
                onSelected={(fieldId) => onColumnsChanged(fieldId, !columns.has(fieldId))}
                localizationContext={`value.meteo_v1.${compact ? "shortcut" : "name"}`}
                tooltipLocalizationContext={`value.meteo_v1.name`}
                data={data}
                doubleHeightFieldsIds={["precip_type"]}
                {...selectionListProps}
            />
        );
}

function CrosshairSelection({ time, color, stickSelection }) {
    const store = useStore();
    const intl = useIntl();

    return (
        <Typography variant={"subtitle1"} style={{ display: "flex", alignItems: "center", height: 20 }}>
            {color ? <span style={{ color }}>{time}</span> : <span>{time}</span>}
            {stickSelection === 1 && (
                <Tooltip title={formatForId(intl, "pages.device.meteogram.deviceState.resetStickSelection")}>
                    <IconButton
                        type={"reset"}
                        onClick={() => {
                            resetStickSelection(store);
                        }}
                        size={"medium"}
                    >
                        <FontAwesomeIcon icon={faUnlink} size={"xs"} color={color} />
                    </IconButton>
                </Tooltip>
            )}
        </Typography>
    );
}

function ForecastNotLoaded() {
    const intl = useIntl();
    return (
        <>
            <div
                style={{
                    color: "gray",
                    fontSize: "smaller",
                    position: "absolute",
                    top: "1.8em",
                }}
            >
                {formatForId(intl, "pages.device.meteogram.forecastNotFound")}
            </div>
        </>
    );
}

function AlertGroupLabel({ level }) {
    const intl = useIntl();
    const theme = useTheme();

    const label = useMemo(() => {
        switch (level) {
            case ALERT_1:
                return formatForId(intl, "pages.device.meteogram.dataPanel.alertLabelOne");
            case ALERT_2:
                return formatForId(intl, "pages.device.meteogram.dataPanel.alertLabelTwo");
            case ALERT_3:
                return formatForId(intl, "pages.device.meteogram.dataPanel.alertLabelThree");
            case ALERT_STATE:
                return formatForId(intl, "pages.device.meteogram.dataPanel.stateLabel");
            case ALERT_DEVICE_OFFLINE:
                return formatForId(intl, "pages.device.meteogram.dataPanel.offlineLabel");
            case ALERT_FAILURE:
                return formatForId(intl, "pages.device.meteogram.dataPanel.failureLabel");
            default:
                return "";
        }
    }, [level]);

    return (
        <div
            style={{
                color: theme.palette.warnings["level_" + level]?.primary || "white",
                width: "2em",
            }}
        >
            {label}
        </div>
    );
}

function AlertGroup({ level, alerts, height, deviceState, compact }) {
    const intl = useIntl();
    const { alertStrings, alertShortcutStrings } = useMemo(() => {
        const allAlerts = [];
        const forecastAlerts = [];

        for (const alert of alerts) {
            for (const a of alert.alerts) {
                if (a.level === ALERT_FORECAST_1 || a.level === ALERT_FORECAST_2 || a.level === ALERT_FORECAST_3) {
                    forecastAlerts.push(a);
                } else {
                    allAlerts.push(a);
                }
            }
        }

        const translatedAlerts = _.map(allAlerts, (alert) => formatWarning(intl, alert, deviceState, false));
        translatedAlerts.sort();

        if (!_.isEmpty(forecastAlerts)) {
            translatedAlerts.unshift(formatForecastWarnings(forecastAlerts, intl));
        }

        const translatedAlertsShortcut = _.map(allAlerts, (alert) => formatWarning(intl, alert, deviceState, true));
        translatedAlertsShortcut.sort();

        if (!_.isEmpty(forecastAlerts)) {
            translatedAlertsShortcut.unshift(formatForecastWarnings(forecastAlerts, intl, 0, compact));
        }

        return {
            alertStrings: _.uniq(translatedAlerts).join(", "),
            alertShortcutStrings: _.uniq(translatedAlertsShortcut).join(", "),
        };
    }, [alerts, intl]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: 20,
            }}
        >
            {_.isEmpty(alerts) ? (
                <></>
            ) : (
                <>
                    <AlertGroupLabel level={level} />
                    <Tooltip title={alertStrings}>
                        <div
                            style={{
                                flexGrow: 1,
                                overflowX: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                width: "100%",
                            }}
                        >
                            {alertShortcutStrings}
                        </div>
                    </Tooltip>
                </>
            )}
        </div>
    );
}

function StateView({ crosshairData, forecastActive, forecastData, classes, alertsViewSettings, height, deviceState, compact }) {
    const intl = useIntl();
    const activeAlerts = crosshairData?.alertData || [];
    const theme = useTheme();

    const data = useMemo(() => {
        let time;
        let color = null;

        if (crosshairData && (crosshairData.selectedDataRow || crosshairData.selectedForecastRow)) {
            time = formatTime(crosshairData.selectedDataRow?.time || crosshairData.selectedForecastRow?.time, true);
            color = crosshairData.stickSelection === 2 ? theme.palette.valueTextColor : crosshairData.stickSelection === 1 ? theme.palette.historyActiveColor : null;
        } else {
            time = formatForId(intl, "pages.device.meteogram.deviceState.timePlaceholder");
        }
        return { time, color };
    }, [crosshairData]);

    const visibleAlertGroups = useMemo(
        () =>
            _.filter(activeAlerts, (alert) => {
                return (
                    (alert.level >= ALERT_1 && alert.level <= ALERT_3 && alertsViewSettings.hasPermAlertView) ||
                    (alert.level === ALERT_FAILURE && alertsViewSettings.hasPermErrors) ||
                    ((alert.level === ALERT_STATE || alert.level === ALERT_DEVICE_OFFLINE) && alertsViewSettings.hasPermStates)
                );
            }),
        [activeAlerts, alertsViewSettings]
    );

    const visibleAlertGroupsWithEmptyAlerts = useMemo(() => {
        return DataPanelAlertPositionKeys.map((level) => {
            const alert = visibleAlertGroups.find((alertGroup) => alertGroup.level === level);
            return alert || { level, alerts: [] };
        });
    }, [visibleAlertGroups]);

    return (
        <div
            className={classes.deviceStateSection}
            style={{
                maxWidth: compact ? 200 : 340,
            }}
        >
            <div className={classes.dataInfo}>
                <CrosshairSelection time={data.time} color={data.color} stickSelection={crosshairData?.stickSelection} />
                {forecastActive && _.isEmpty(forecastData) && <ForecastNotLoaded />}
            </div>
            <div className={classes.alerts}>
                {_.map(visibleAlertGroupsWithEmptyAlerts, (alert) => (
                    <AlertGroup key={alert.level} level={alert.level} alerts={alert.alerts} height={height} deviceState={deviceState} compact={compact} />
                ))}
            </div>
        </div>
    );
}

export function DataPanel({ columns, hiddenColumns, onColumnsChanged, forecastActive, forecastData, alertsViewSettings, height, device, compact }) {
    const classes = useStyles();

    const crosshairData = useCrosshairData();
    const deviceState = useDeviceState(device.id);

    return (
        <div className={classes.root} style={{ width: compact ? 200 : 340 }}>
            <StateView
                crosshairData={crosshairData}
                forecastActive={forecastActive}
                forecastData={forecastData}
                classes={classes}
                alertsViewSettings={alertsViewSettings}
                height={height}
                deviceState={deviceState}
                compact={compact}
            />

            <div className={classes.fields}>
                <DataFields
                    columns={columns}
                    hiddenColumns={hiddenColumns}
                    onColumnsChanged={onColumnsChanged}
                    selectedRow={crosshairData?.selectedDataRow}
                    selectedForecastRow={crosshairData?.selectedForecastRow}
                    forecastActive={forecastActive && !_.isEmpty(forecastData)}
                    compact={compact}
                />
            </div>
        </div>
    );
}
