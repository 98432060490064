import React, { useEffect, useMemo, useState } from "react";
import { useActiveView } from "../../../Utils/Data/hooks/deviceDataView";
import { useBackgroundImage, useEndDeviceData, useRnrData, useSliceDelay } from "../../../Utils/Data/actions/RnrData";
import { useTheme } from "@material-ui/core";
import _ from "lodash";
import { DeviceStateTooltip, RnrDeviceTooltip } from "./RnrDeviceTooltip";
import LoadingScope from "../../../Components/LoadingScope";
import moment from "moment";

function isHorizontal(rotation) {
    return Math.abs(rotation) === 0 || Math.abs(rotation) === 180;
}

function getElementPosition(backgroundElem, elemId) {
    const elem = backgroundElem.querySelector(`#${elemId}`);
    if (!elem) {
        return;
    }
    const svg = elem.ownerSVGElement;

    const pt = svg.createSVGPoint();

    pt.x = elem.x.baseVal.value;
    pt.y = elem.y.baseVal.value;

    const pt2 = svg.createSVGPoint();
    pt2.x = elem.x.baseVal.value + elem.width.baseVal.value;
    pt2.y = elem.y.baseVal.value + elem.height.baseVal.value;

    const ptTransformed = pt.matrixTransform(elem.getScreenCTM());
    const pt2Transformed = pt2.matrixTransform(elem.getScreenCTM());

    let x = 0,
        y = 0,
        width = 0,
        height = 0;

    if (pt2Transformed.x < ptTransformed.x) {
        x = pt2Transformed.x;
        width = ptTransformed.x - pt2Transformed.x;
    } else {
        x = ptTransformed.x;
        width = pt2Transformed.x - ptTransformed.x;
    }

    if (pt2Transformed.y < ptTransformed.y) {
        y = pt2Transformed.y;
        height = ptTransformed.y - pt2Transformed.y;
    } else {
        y = ptTransformed.y;
        height = pt2Transformed.y - ptTransformed.y;
    }

    let rotation = 0;

    for (let i = 0; i < elem.transform.baseVal.numberOfItems; i++) {
        const item = elem.transform.baseVal.getItem(i);
        if (item.type === SVGTransform.SVG_TRANSFORM_ROTATE) {
            rotation = item.angle;
        }
    }

    return { x, y, width, height, rotation, isHorizontal: isHorizontal(rotation) };
}

function Image({ src, alt, style }) {
    const [error, setError] = useState(false);

    if (error) {
        return <img src={`/rnr-icon/missing_symbol.svg`} alt={alt} style={style} />;
    } else {
        return <img src={src} alt={alt} style={style} onError={(e) => setError(true)} />;
    }
}

function SymbolArea({ displayedSymbols, position, device, hasError, backgroundColor }) {
    const displayedSymbolsComputed = useMemo(() => {
        if (hasError) {
            return ["comm_lost"];
        } else if (_.isEmpty(displayedSymbols)) {
            return [];
        } else if (position.rotation === 90) {
            return displayedSymbols.slice().reverse();
        }
        return displayedSymbols;
    }, [displayedSymbols, position.rotation]);

    return (
        <div
            style={{
                display: "flex",
                backgroundColor,
                flexDirection: position.isHorizontal ? "column" : "row",
                width: position.width,
                height: position.height,
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {displayedSymbolsComputed.map((symbol) => (
                <div
                    style={{
                        width: position.isHorizontal ? position.width / displayedSymbolsComputed.length : position.width,
                        height: position.isHorizontal ? position.height : position.height / displayedSymbolsComputed.length,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Image
                        src={`/rnr-icon/${symbol}.svg`}
                        alt={device?.id}
                        style={{
                            transform: `rotate(${position.rotation}deg)`,
                            height: position.isHorizontal ? "100%" : position.width / displayedSymbolsComputed.length,
                            width: position.isHorizontal ? "100%" : position.height / displayedSymbolsComputed.length,
                        }}
                    />
                </div>
            ))}
        </div>
    );
}

function usePosition(elemId, backgroundElemRef, refreshTrigger) {
    const [position, setPosition] = useState({});
    useEffect(() => {
        const handleResize = () => {
            if (backgroundElemRef.current) {
                setPosition(getElementPosition(backgroundElemRef.current, elemId));
            }
        };

        window.addEventListener("resize", handleResize);

        const timeout = setTimeout(() => {
            handleResize();
        }, 100);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
            clearTimeout(timeout);
        };
    }, [backgroundElemRef, elemId, refreshTrigger]);

    return position;
}

function Warning({ position, symbols }) {
    if (_.isEmpty(symbols)) {
        return null;
    }
    return (
        <div
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: position.width,
                height: position.height,
            }}
        >
            <SymbolArea displayedSymbols={symbols} position={position} backgroundColor={"unset"} />
        </div>
    );
}

function Item({ backgroundElemRef, device, rnrTrafficStateData, symbolOverride, refreshTrigger }) {
    const position = usePosition(device.id, backgroundElemRef, refreshTrigger);
    const countdownPosition = usePosition(`${device.id}-countdown`, backgroundElemRef, refreshTrigger);

    const { rnrState, endDeviceState } = useEndDeviceData(device, rnrTrafficStateData);
    const { displayedSymbols, previewInfo } = useMemo(() => {
        let displayedSymbols = [];
        let previewInfo = null;

        if (symbolOverride) {
            const ret = symbolOverride.find((s) => s.id === device.id);
            displayedSymbols = ret?.symbols || ["without_change"];
            previewInfo = ret;
        } else {
            displayedSymbols = endDeviceState?.current_display?.symbols || [];
        }
        return { displayedSymbols, previewInfo };
    }, [symbolOverride, endDeviceState]);

    const warningSymbols = useMemo(() => {
        if (symbolOverride) {
            if (previewInfo?.isMandatory) {
                return ["mandatory"];
            }
        } else if (!endDeviceState?.hasCriticalError) {
            if (endDeviceState?.warnings?.is_last_active) {
                return ["error_frame"];
            } else if (endDeviceState?.infos?.is_last_active && endDeviceState?.infos?.last?.message === "door_open") {
                return ["warning_frame"];
            }
        }
        return undefined;
    }, [displayedSymbols, previewInfo, endDeviceState, position]);

    if (_.isEmpty(position)) {
        return null;
    }

    return (
        <>
            <DeviceStateTooltip device={device} deviceState={endDeviceState} rnrState={rnrState} previewInfo={previewInfo}>
                <div
                    style={{
                        position: "absolute",
                        left: position.x,
                        top: position.y,
                        width: position.width,
                        height: position.height,
                    }}
                >
                    <SymbolArea displayedSymbols={displayedSymbols} position={position} device={device} hasError={_.isEmpty(symbolOverride) && endDeviceState.hasError} backgroundColor={"black"} />
                    <Warning position={position} symbols={warningSymbols} />
                </div>
            </DeviceStateTooltip>
        </>
    );
}

function CountdownItem({ backgroundElemRef, rnrTrafficStateData, slice, symbolsOverride }) {
    const position = usePosition(`${slice.id}-countdown`, backgroundElemRef);
    const countdownData = useSliceDelay(slice, rnrTrafficStateData);

    const countdownDataComputed = useMemo(() => {
        if (_.isEmpty(symbolsOverride)) {
            return countdownData;
        } else {
            return symbolsOverride.find((item) => item.nrId === slice.id)?.delay;
        }
    }, [symbolsOverride, countdownData]);

    if (!countdownDataComputed) {
        return null;
    }

    return (
        <div
            style={{
                position: "absolute",
                left: position.x,
                top: position.y,
                width: position.width,
                height: position.height,
            }}
        >
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "0.68rem",
                    fontWeight: "500",
                }}
            >
                {moment.utc(countdownDataComputed).format("mm:ss")}
            </div>
        </div>
    );
}

function RnrItem({ backgroundElemRef, rnrDevice, rnrTrafficStateData, refreshTrigger }) {
    const rnrState = useRnrData(rnrDevice, rnrTrafficStateData);
    const position = usePosition(rnrDevice.id, backgroundElemRef, refreshTrigger);
    const theme = useTheme();

    const disabled = !rnrDevice?.systemDevice?.enabled;
    const hasError = rnrState?.errors?.is_last_active || disabled;
    const hasWarning = rnrState?.warnings?.is_last_active;

    const hasIoState = (statesId) => {
        for (const ioDevice of rnrDevice?.ioDevices) {
            const states = rnrState?.state?.current_state?.slices?.[ioDevice.sliceId]?.[ioDevice.endDeviceId]?.[statesId]?.histogram;
            if (states) {
                for (const state of Object.values(states)) {
                    if (state.is_active) {
                        return true;
                    }
                }
            }
        }

        return false;
    };

    const backgroundImage = useMemo(() => {
        let backrgroundImage = "";

        const hasInfoIo = hasIoState("infos");
        const hasWarningIo = hasIoState("warnings");
        const hasErrorIo = hasIoState("errors");

        if (hasError) {
            backrgroundImage = "RNR_comm_lost.svg";
        } else if (hasWarningIo || hasErrorIo) {
            backrgroundImage = "RNR_error.svg";
        } else if (hasWarning || hasInfoIo) {
            backrgroundImage = "RNR_door_open.svg";
        } else {
            backrgroundImage = "RNR_OK.svg";
        }
        return backrgroundImage;
    }, [disabled, hasError, hasWarning, theme, rnrState]);

    if (_.isEmpty(position)) {
        return null;
    }

    return (
        <RnrDeviceTooltip rnrState={rnrState} rnrDevice={rnrDevice} trafficStatesData={rnrTrafficStateData}>
            <div
                style={{
                    position: "absolute",
                    left: position.x,
                    top: position.y,
                    width: position.width,
                    height: position.height,
                }}
            >
                <img src={`/rnr-icon/${backgroundImage}`} alt={backgroundImage} style={{ width: "100%", height: "100%" }} />
            </div>
        </RnrDeviceTooltip>
    );
}

export function SvgView({ endDevices, rnrTrafficStateData, symbolsOverride, rnrDevices = [], fillParent = true, refreshTrigger = null, slices }) {
    const ref = React.useRef(null);
    const view = useActiveView();
    const backgroundImage = useBackgroundImage(view);

    return (
        <>
            <LoadingScope loading={!backgroundImage?.data} showLoading={true} dialog={false}>
                {backgroundImage?.data && (
                    <div
                        ref={ref}
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: fillParent ? "flex-end" : "center",
                            alignItems: fillParent ? "center" : "unset",
                        }}
                        dangerouslySetInnerHTML={{ __html: Buffer.from(backgroundImage.data, "base64") }}
                    />
                )}

                {endDevices
                    .filter((device) => device?.endDevice?.type === "znp")
                    .map((device) => (
                        <Item device={device} backgroundElemRef={ref} rnrTrafficStateData={rnrTrafficStateData} symbolOverride={symbolsOverride} refreshTrigger={refreshTrigger} />
                    ))}

                {rnrDevices.map((rnrDevice) => (
                    <RnrItem rnrDevice={rnrDevice} backgroundElemRef={ref} rnrTrafficStateData={rnrTrafficStateData} refreshTrigger={refreshTrigger} />
                ))}

                {slices?.map((slice) => (
                    <CountdownItem slice={slice} backgroundElemRef={ref} rnrTrafficStateData={rnrTrafficStateData} symbolsOverride={symbolsOverride} />
                ))}
            </LoadingScope>
        </>
    );
}
